import React, { useContext, useEffect, useReducer, useState } from 'react';
import logo from './logo.svg';
import './App.css';
import {EventDashboard} from './components/EventDashboard/EventDashboard';
import {LoginForm} from './components/LoginForm/LoginForm';
import {FilterBar} from './components/FilterBar/FilterBar';
import { DashboardAction, DashboardActionType, FetchType } from './reducer';
import { StoreContext, StoreProvider } from './reducerProvider';
import Button from '@mui/material/Button';
import { EventDetailDialog } from './components/EventDetailDialog';
import {
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { EventSelect } from "./components/EventDashboard";
import {
  getMarketingEventUserDetail,
  getMarketingEventUsers,
} from "./api/marketing";
import jwt from "jwt-decode";

function App() {
  const { state, dispatch } = useContext(StoreContext);
  // login token
  const [loginToken, setLoginToken] = useState<null | string>(null);

  // dialog
  const [open, setOpen] = React.useState(false);

  // event select
  const [event, setEvent] = React.useState("silver-gift");

  const handleChange = (event: SelectChangeEvent) => {
    setEvent(event.target.value as string);
  };

  const handleClickOpen = (userId: string) => {
    dispatch(
      new DashboardAction(DashboardActionType.FETCH_DETAIL_DATA_START, userId)
    );
    setTimeout(() => setOpen(true), 30);
  };

  const handleClose = (value: string) => {
    setOpen(false);
  };

  const toGroupPage = () => {
    window.location.href =
      "https://ge.kid-pro.com/admin?loginToken=" +
      loginToken +
      "&event=" +
      event +
      "&role=ADMIN";
  };

  useEffect(() => {
    const token = localStorage.getItem("loginToken");
    if (token) {
      const user: any = jwt(token!);

      if (user.role !== "ADMIN") {
        return setLoginToken(null);
      }
      setLoginToken(localStorage.getItem("loginToken"));
    }
  }, []);

  useEffect(() => {
    if (!loginToken) return;
    dispatch(new DashboardAction(DashboardActionType.FETCH_DATA_START, null));
    toGroupPage();
  }, [loginToken]);

  useEffect(() => {
    if (!loginToken) return;
    if (!state.isLoading) return;
    toGroupPage();
    console.log("fetch data");

    // switch (state.fetchType) {
    //   case FetchType.LIST:
    //     getMarketingEventUsers(event, loginToken!, {
    //       email: state.filter.email,
    //       limit: state.filter.limit,
    //       offset: state.filter.offset,
    //     })
    //       .then((res) => res.json())
    //       .then((res) => {
    //         dispatch(
    //           new DashboardAction(DashboardActionType.FETCH_DATA_SUCCESS, res)
    //         );
    //       })
    //       .catch((err) => {
    //         dispatch(
    //           new DashboardAction(DashboardActionType.FETCH_DATA_ERROR, err)
    //         );
    //       });
    //     break;
    //   case FetchType.DETAIL:
    //     if (state.currUserId === null) {
    //       console.error("currUserId is null");
    //       return;
    //     }
    //     getMarketingEventUserDetail(event, loginToken!, state.currUserId)
    //       .then((res) => res.json())
    //       .then((res) => {
    //         dispatch(
    //           new DashboardAction(
    //             DashboardActionType.FETCH_DETAIL_DATA_SUCCESS,
    //             res
    //           )
    //         );
    //       })
    //       .catch((err) => {
    //         dispatch(
    //           new DashboardAction(
    //             DashboardActionType.FETCH_DETAIL_DATA_ERROR,
    //             err
    //           )
    //         );
    //       });
    //     break;
    // }
  }, [state.isLoading]);

  useEffect(() => {
    dispatch(new DashboardAction(DashboardActionType.FETCH_DATA_START, null));
  }, [event]);



  return (
    <div className="App">
      <Container maxWidth="lg">
        {loginToken ? (
          <div>
            <EventSelect event={event} handleChange={handleChange} />
            <EventDashboard
              prop={state.data}
              page={state.filter.page}
              rowsPerPage={state.filter.limit}
              count={state.filter.total}
              handleDetailClick={handleClickOpen}
              handleChangePage={(page) => {
                dispatch(
                  new DashboardAction(DashboardActionType.CHANGE_PAGE, page)
                );
                dispatch(
                  new DashboardAction(
                    DashboardActionType.FETCH_DATA_START,
                    null
                  )
                );
              }}
              handleChangeRowsPerPage={(e) => {
                dispatch(
                  new DashboardAction(
                    DashboardActionType.CHANGE_LIMIT,
                    parseInt(e.target.value, 10)
                  )
                );
                dispatch(
                  new DashboardAction(
                    DashboardActionType.FETCH_DATA_START,
                    null
                  )
                );
              }}
              search={state.filter.email}
              handleSearch={(e) => {
                dispatch(
                  new DashboardAction(
                    DashboardActionType.SEACH_EMAIL,
                    e.target.value
                  )
                );
                dispatch(
                  new DashboardAction(
                    DashboardActionType.FETCH_DATA_START,
                    null
                  )
                );
              }}
            />
          </div>
        ) : (
          <LoginForm handleLogin={setLoginToken} />
        )}
      </Container>

      <Button onClick={toGroupPage}>toGroupPage</Button>

      <EventDetailDialog
        open={open}
        onClose={handleClose}
        data={state.detailData}
      />
    </div>
  );
}

export default App;
