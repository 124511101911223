import { Box, Button, Stack, TextField } from '@mui/material';
import React, { useEffect } from 'react';
import {JSEncrypt} from 'jsencrypt';
import {decode as base64_decode, encode as base64_encode} from 'base-64';
import { apiOptionLogin } from "../../api";
import jwt from "jwt-decode";

export interface LoginFormProps {
  handleLogin: (loginToken: string) => void;
}

export function LoginForm({ handleLogin }: LoginFormProps) {
  const [state, setState] = React.useState({
    account: "",
    password: "",
  });

  useEffect(() => {
    console.log("LoginForm mounted");
  }, []);

  const login = async () => {
    const loginToken = await apiOptionLogin({
      account: state.account,
      password: state.password,
    });

    if (!loginToken) {
      return;
    }

    localStorage.setItem("loginToken", loginToken);
    handleLogin(loginToken);
  };

  return (
    <Box
      component="form"
      sx={{
        "& .MuiTextField-root": { m: 1, width: "25ch" },
      }}
      noValidate
      autoComplete="off"
    >
      <Stack spacing={2}>
        <div>
          <TextField
            id="account"
            label="Account"
            // defaultValue=""
            value={state.account}
            onChange={(e) => {
              setState({
                ...state,
                account: e.target.value,
              });
            }}
          />
          <TextField
            id="outlined-password-input"
            label="Password"
            type="password"
            autoComplete="current-password"
            value={state.password}
            onChange={(e) => {
              setState({
                ...state,
                password: e.target.value,
              });
            }}
          />
        </div>
        <div>
          <Button
            variant="contained"
            onClick={async () => {
              await login();
            }}
          >
            SignIn
          </Button>
        </div>
      </Stack>
    </Box>
  );
}
