import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import React from "react";

export function EventSelect({
  event,
  handleChange,
}: {
  event: string;
  handleChange: (event: SelectChangeEvent) => void;
}) {
  return (
    <FormControl fullWidth>
      <InputLabel id="demo-simple-select-label">活動</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={event}
        label="活動"
        onChange={handleChange}
      >
        <MenuItem value={"share-yoga"}>瑜伽球 活動</MenuItem>
        <MenuItem value={"silver-gift"}>銀卡 活動</MenuItem>
      </Select>
    </FormControl>
  );
}
