import JSEncrypt from "jsencrypt";

const host = "https://uaa.kid-pro.com";

export const apiGetPublicKey = fetch(`${host}/api/uaa/auth/public_key`, {
  method: "GET",
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

export const apiLogin = (encrypted: string, keyId: string) =>
  fetch(`${host}/api/uaa/login_crypto`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "x-crypt-key-id": keyId,
    },
    body: JSON.stringify({
      data: encrypted,
    }),
  });

// export const apiOptionLogin = async ({
//   account,
//   password,
// }: {
//   account: string;
//   password: string;
// }) => {
//   const data = await apiGetPublicKey.then((response) => {
//     return response.json();
//   });

//   const { id: keyId, key } = data.data;
//   const encrypt = new JSEncrypt({
//     default_key_size: "512",
//   });
//   encrypt.setPublicKey(key);

//   const encrypted = encrypt.encrypt(
//     JSON.stringify({
//       redirectUrl: "",
//       email: account,
//       password: password,
//     })
//   );
//   if (!encrypted) {
//     throw new Error("Encrypt failed");
//   }

//   const loginData = await apiLogin(encrypted, keyId).then((response) => {
//     return response.json();
//   });

//   return loginData.data.loginToken;
// };

export const apiOptionLogin = async ({
  account,
  password,
}: {
  account: string;
  password: string;
}) => {
  const loginData = await fetch(`${host}/api/uaa/login`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({
      email: account,
      password: password,
      redirectUrl: "",
    }),
  }).then((response) => {
    return response.json();
  });

  return loginData.data.loginToken;
};
